import React from "react";
import "./ProductsSection.css";
import Chip from "../Chip/Chip";
import Button from "../Button/Button";
import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";

const ProductsSection = () => {
  return (
    <section className="products-section">
      <Chip text="Our Products" />
      <p className="products-section-heading">
        Engagement tools to help you build better connections.
      </p>
      <div className="product-cards-container">
        <div className="product-card-container-wrapper">
          <div className="product-card-container-top-right-gradient"></div>
          <div className="product-card-container">
            <div className="product-card-left-container">
              <img src="/assets/images/product1.svg" alt="" />
            </div>
            <div className="product-right-container">
              <Chip text="Intent-Based Semantic Search" />
              <p className="product-card-heading">Get Precise Answers, Fast</p>
              <p className="product-card-desc">
                Help visitors find the right answers quickly without digging
                through all your content. Provide precise, intent-driven answers
                to boost engagement, reduce bounce rates, and drive conversions.
              </p>

              <div className="product-card-btns-container">
                {/* <Button text="Learn More" type="filled" action={() => {}} /> */}
                {/* <Button text="Request a demo" type="border" action={() => {}} /> */}
                <CalendlyPopupButton text="Request a demo" className="white" />
              </div>
            </div>
          </div>
        </div>
        <div className="product-card-container">
          <div className="product-card-left-container">
            <img src="/assets/images/product2.svg" alt="" />
          </div>
          <div className="product-right-container">
            <Chip text="AI-Driven Multimodal Content Discovery" />

            <p className="product-card-heading">
              Surface the Right Content Every Time
            </p>
            <p className="product-card-desc">
              Serve relevant content based on user prompts, helping users not
              only get answers but also discover valuable company-owned content.
              Accelerate decision-making and boost the ROI on your content
              investments.
            </p>
            <div className="product-card-btns-container">
              {/* <Button text="Learn More" type="filled" action={() => {}} /> */}
              {/* <Button text="Request a demo" type="border" action={() => {}} /> */}
              <CalendlyPopupButton text="Request a demo" className="white" />
            </div>
          </div>
        </div>
        <div className="product-card-container">
          <div className="product-card-left-container">
            <img src="/assets/images/product3.svg" alt="" />
          </div>
          <div className="product-right-container">
            <Chip text="A New Era of B2B Engagement" />

            <p className="product-card-heading">
              Content Whitespace & Analytics
            </p>
            <p className="product-card-desc">
              Gain insights into user search behavior and identify missing
              content. Optimize your SEO and content strategy with rich
              analytics mapped to user queries, ensuring you're meeting user
              demand.
            </p>
            <div className="product-card-btns-container">
              {/* <Button text="Learn More" type="filled" action={() => {}} /> */}
              {/* <Button text="Request a demo" type="border" action={() => {}} /> */}
              <CalendlyPopupButton text="Request a demo" className="white" />
            </div>
          </div>
        </div>
        <div className="product-card-container">
          <div className="product-card-left-container">
            <img src="/assets/images/product3.svg" alt="" />
          </div>
          <div className="product-right-container">
            <Chip text="Sales Cheatsheet" />

            <p className="product-card-heading">
              Empower Your Sales Team with Instant Answers
            </p>
            <p className="product-card-desc">
              Equip your sales team with instant, accurate answers for sales
              calls and follow-ups. Drive precise pitching, close deals faster,
              and reduce customer acquisition costs.
            </p>
            <div className="product-card-btns-container">
              {/* <Button text="Learn More" type="filled" action={() => {}} /> */}
              {/* <B÷utton text="Request a demo" type="border" action={() => {}} /> */}
              <CalendlyPopupButton text="Request a demo" className="white" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsSection;
