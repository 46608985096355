import React from 'react'
import "./ReviewSection.css"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";


const ReviewSection = () => {
  return (
    <section className="review-section">
      <div className="review-container">
        <div className="review-text-container">
          <p className="review-text-heading">
            Webless AI has been a game-changer for revVana.
          </p>
          <p className="review-subtext">
            It’s easy to use and was a breeze to implement (with tons of
            assistance from their team), quickly becoming an integral part of
            our workflow. The tool delivers accurate and relevant search
            results, making it effortless to find the information we need.
          </p>
        </div>
        <div className="review-info-container">
          <div
            className="reviewer-info"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/brandon-thomison-626735162/"
              )
            }
          >
            <img src="/assets/images/brandon-image.jpg" alt="" />
            <div className="company-info">
              <p className="company-info-heading">Brandon Thomison</p>
              <p className="company-info-subtext">Marketing Leader, revVana</p>
            </div>
          </div>
          {/* <div className="buttons-container">
            <div>
              <MdKeyboardArrowLeft />
            </div>
            <div>
              <MdKeyboardArrowRight />
            </div>
          </div> */}
          <img
            className="brand-logo"
            src="/assets/images/revvana-logo.png"
            alt=""
          />
        </div>
        <div className="quote-symbol-container">
          <img src="/assets/images/quote-symbol.svg" alt="" />
        </div>
      </div>
      <div
        className="reviewer-info bottom"
        onClick={() =>
          window.open("https://www.linkedin.com/in/brandon-thomison-626735162/")
        }
      >
        <img src="/assets/images/brandon-image.jpg" alt="" />
        <div className="company-info">
          <p className="company-info-heading">Brandon Thomison</p>
          <p className="company-info-subtext">Marketing Leader, revVana</p>
        </div>
      </div>
    </section>
  );
}

export default ReviewSection