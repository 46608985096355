import React from "react";
import Chip from "../Chip/Chip";
import "./WhyWeblessSection.css";

const WhyWeblessSection = () => {
  return (
    <section className="why-webless-section">
      <div className="bottom-center-gradient"></div>
      <Chip text="Why Webless?" />
      <p className="why-webless-section-heading">
        Webless is Built to Engage & Convert B2B Prospects and Partners
      </p>
      <div className="why-webless-cards-container">
        <div className="why-webless-card">
          <div className="why-webless-card-image-container">
            <img src="/assets/images/message-circle.svg" alt="" />
          </div>
          <p className="why-webless-card-heading">
            Brand voice and Interaction Style
          </p>
          <p className="why-webless-card-desc">
            Webless crafts each interaction with personalized tone and set
            behaviors, aligning with your brand's unique identity.
          </p>
        </div>

        <div className="why-webless-card">
          <div className="why-webless-card-image-container">
            <img src="/assets/images/umbrella.svg" alt="" />
          </div>
          <p className="why-webless-card-heading">
            Guardrails to avoid hallucinations
          </p>
          <p className="why-webless-card-desc">
            Define specific targets for your agent to achieve and steer it
            towards preferred actions. Build customer trust by establishing
            limits to keep it on topic and ensure appropriate decision-making
          </p>
        </div>

        <div className="why-webless-card">
          <div className="why-webless-card-image-container">
            <img src="/assets/images/book-open.svg" alt="" />
          </div>

          <p className="why-webless-card-heading">
            In-depth understanding of your content & workflows
          </p>
          <p className="why-webless-card-desc">
            Infuse your agent with your knowledge and workflows, enabling it to
            understand the context of every discussion and provide quick,
            innovative responses.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyWeblessSection;
