import React from "react";
import "./InfoSection.css";
import Button from "../Button/Button";
import Chip from "../Chip/Chip";
import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";

const InfoSection = () => {
  return (
    <section className="info-section">
      <div className="info-section-left-container">
        <Chip text="A New Era of B2B Engagement" type="light" />

        <p className="info-section-heading">A New Era of B2B Engagement</p>
        <p className="info-section-desc">
          In the era of GPT, customers lack the patience to browse your 500-page
          website for the content they need—they demand instant, relevant
          answers. Webless delivers tailored search results and personalized
          content that speaks directly to their needs on your site. <br />
          <br /> With Webless, you can transform every visit into a meaningful
          interaction, elevating your B2B site from a static brochure to dynamic
          experiences that drive higher conversions and customer satisfaction
          effortlessly.
        </p>
        <div className="info-section-btns-container">
          {/* <Button text="Learn More" type="filled" action={() => {}} /> */}
          <CalendlyPopupButton text="Request a demo" />
          {/* <Button text="Request a demo" type="border" action={() => {}} /> */}
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
