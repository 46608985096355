import React from 'react'
import "./ActionSection.css"
import Chip from '../Chip/Chip';
import Button from '../Button/Button';
import CalendlyPopupButton from '../Calendly/CalendlyPopupButton';

const ActionSection = () => {
  return (
    <section className="action-section">
      <div className="action-section-container">
        <Chip text="Webless AI in action" />
        <p className="action-section-heading">
          Transform your B2B engagement strategy today
        </p>
        <p className="action-section-desc">
          Discover how Webless can elevate your website engagement, boost
          conversions, and reduce your customer acquisition costs
        </p>
        {/* <Button text="Request a demo" type="filled" action={() => {}} /> */}
        <CalendlyPopupButton text="Request a demo" className="filled" />
      </div>
      <div className="top-right-gradient"></div>
      <div className="bottom-left-gradient"></div>
    </section>
  );
}

export default ActionSection