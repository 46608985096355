import { Link } from "react-router-dom";
import Button from "../Button/Button";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";
import { useState } from "react";

const Navbar = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  return (
    <nav className="navbar-container">
      <div className="navbar-left-container">
        <div className="nav-logo-container">
          <Link to="/">
            <img src="/assets/images/webless_logo.jpg" alt="Webless Logo" />
          </Link>
        </div>
      </div>

      <div className="navbar-container-right">
        <div className="navbar-right-container-hamburger">
          <GiHamburgerMenu onClick={() => setIsOptionsOpen((prev) => !prev)} />
          {isOptionsOpen && (
            <div className="hamburger-options">
              {/* <Link to="/" className="hamburger-link">
                Home
              </Link> */}
              {/* <Link to="/products" className="hamburger-link">
                Products
              </Link>
              <Link to="/resources" className="hamburger-link">
                Resources
              </Link>
              <Link to="/about" className="hamburger-link">
                About
              </Link> */}
              <CalendlyPopupButton className="filled" />
            </div>
          )}
        </div>
        <div className="navbar-right-container-buttons-wrapper">
          {/* <Button text="Login" type="" action={() => {}} /> */}
          <CalendlyPopupButton className="filled" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
