import React from 'react'
import "./ConnectWeblessSections.css"

const ConnectWeblessSections = () => {
  return (
    <div className="connect-webless-section">
      <p>Seamlessly integrate with industry-leading platforms.</p>
      <div className="company-logos-container">
        <img src="assets/images/logos/gtm-logo.svg" alt="" />
        <img src="assets/images/logos/hubspot-logo.svg" alt="" />
        <img src="assets/images/logos/ga-logo.svg" alt="" />
        <img src="assets/images/logos/salesforce-logo.svg" alt="" />
        <img src="assets/images/logos/clearbit-logo.svg" alt="" />
        <img src="assets/images/logos/6sense-logo.svg" alt="" />
        <img src="assets/images/logos/marketo-logo.svg" alt="" />
      </div>
    </div>
  );
}

export default ConnectWeblessSections