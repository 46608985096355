import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUsage from "./components/TermsOfUsage/TermsOfUsage";
import Login from "./pages/Login/Login";
import PrivacayPolicy2 from "./components/PrivacayPolicy";
import ScrollToTop from "./utils/ScrollToTop";


function App() {
  return (
    <div className="App">
      <Navbar />
      {/* <PrivacayPolicy2/> */}

      <div className="content">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUsage />} />
          <Route path="login" element={<Login />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
