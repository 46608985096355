import React from 'react'
import "./Chip.css"

const Chip:React.FC<{text:string, type?: string}> = ({text, type}) => {
  return (
    <div className={`chip-container ${type}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
      >
        <path d="M11.5 0.5C11.5 0.5 13.3228 5.23354 15.5447 7.45535C17.7665 9.67716 22.5 11.5 22.5 11.5C22.5 11.5 17.7665 13.3228 15.5447 15.5447C13.3228 17.7665 11.5 22.5 11.5 22.5C11.5 22.5 9.67716 17.7665 7.45535 15.5447C5.23354 13.3228 0.5 11.5 0.5 11.5C0.5 11.5 5.23354 9.67716 7.45535 7.45535C9.67716 5.23354 11.5 0.5 11.5 0.5Z" />
      </svg>
      <p>{text}</p>
    </div>
  );
}

export default Chip