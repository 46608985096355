import React from "react";
import "./HeroSection.css";
import Chip from "../Chip/Chip";
import Button from "../Button/Button";
import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <Chip text="Webless AI is here!" />
      <p className="hero-section-heading">
        Transform your B2B website from an information brochure to a conversion
        powerhouse
      </p>

      <p className="hero-section-sub-text">
        Webless leverages LLM-powered semantic search and multimodal content
        discovery to boost conversions, enhance lead quality, and slash customer
        acquisition costs.
      </p>
      <div className="hero-button-container">
        <CalendlyPopupButton className="white"/>
        {/* <Button text="Schedule a Demo" type="border white" action={() => {}} /> */}
      </div>
      <div className="hero-section-image">
        <img src="/assets/images/hero-section-image.svg" alt="" />
      </div>
      {/* <div className="hero-gif-player-parent-container">
        <div className="hero-player-left-info">
          <div className="hero-player-left-info-card">
            <div className="hero-player-left-info-top-section">
              <p>25%</p>
              <p>Reduction in CAC</p>
            </div>
            <div className="hero-player-left-info-bottom-section">
              <p>By letting your content guide the conversation.</p>
            </div>
          </div>
        </div>
        <div className="hero-player-left-info">
          <div className="hero-player-left-info-card">
            <div className="hero-player-left-info-top-section">
              <p>30%</p>
              <p>Increase in Engagement</p>
            </div>
            <div className="hero-player-left-info-bottom-section">
              <p>Through personalized, context-aware interactions.</p>
            </div>
          </div>
        </div>

        <div className="hero-player-right-info">
        <div>
          <p>25%</p>
          <p>Reduction in CAC</p>
        </div>
        <div>
          <p>By letting your content guide the conversation.</p>
        </div>
      </div>
      </div> */}
    </section>
  );
};

export default HeroSection;
