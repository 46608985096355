import React from "react";
import "./Footer.css";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <ul className="footer-left-section">
        <li>
          <Link className="link-tag" to={"privacy-policy"}>
            Privacy Policy
          </Link>
        </li>
        <hr />
        <li>
          <Link className="link-tag" to="terms-of-use">Terms of Use</Link>
        </li>
      </ul>
      <FaLinkedin
        className="linkedin-icon"
        onClick={() => {
          window.open("https://www.linkedin.com/company/webless-ai/", "_blank");
        }}
      />
    </div>
  );
};
export default Footer;
