import React from 'react'
import "./Login.css"

const Login = () => {
  return (
    <div className="login-page">
      <div className="login-page-top-right-gradient"></div>
      <div className="login-page-bottom-left-gradient"></div>
    </div>
  );
}

export default Login