import React from "react";
import "./Home.css";
import HeroSection from "../components/HeroSection/HeroSection";
import WhyWeblessSection from "../components/WhyWeblessSection/WhyWeblessSection";
import ConnectWeblessSections from "../components/ConnectWeblessSections/ConnectWeblessSections";
import ProductsSection from "../components/ProductsSection/ProductsSection";
import ReviewSection from "../components/ReviewSection/ReviewSection";
import ActionSection from "../components/ActionSection/ActionSection";
import InfoSection from "../components/InfoSection/InfoSection";
const Home = () => {
  return (
    <div className="homepage">
      <HeroSection />
      {/* <CalendlyIframe url="https://calendly.com/rohit-webless" /> */}
      {/* <CalendlyPopupButton /> */}

      <ConnectWeblessSections />
      <InfoSection />
      <ProductsSection />
      <WhyWeblessSection />
      <ReviewSection />
      <ActionSection />
    </div>
  );
};

export default Home;
